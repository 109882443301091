<template>
  <form @submit.prevent="handleSave" method="POST">
    <div class="shadow overflow-hidden sm:rounded-md">
      <div class="px-4 py-5 bg-white sm:p-6">
        <div class="grid grid-cols-6 gap-6">
          
          <div class="col-span-6 sm:col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">Naam persoon</label>
            <input type="text" name="name" id="first_name" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.name">
          </div>

          <div class="col-span-6 sm:col-span-3">
            <label for="company_name" class="block text-sm font-medium text-gray-700">Bedrijf</label>
            <input type="text" name="company_name" id="company_name" autocomplete="company_name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.company_name">
          </div>

          <div class="col-span-6 sm:col-span-6">
            <label for="text" class="block text-sm font-medium text-gray-700">Quote</label>
            <textarea name="text" id="description" autocomplete="given-name" class="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" v-model="c.text"></textarea>
          </div>

        </div>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500">
          <svg v-show="loading" class="animate-spin h-4 mr-2 w-4 rounded-full bg-transparent border-2 border-transparent border-opacity-50" style="border-right-color: white; border-top-color: white;" viewBox="0 0 24 24"></svg>
          {{loading ? 'saving': 'save'}}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";

import QuoteService from '../../../services/admin/quotes/service';

export default {
  name: 'QuoteComponent',
  props: ['quote'],
  setup(props) {
    const loading = ref(true);
    loading.value = false;

    return {
      c: props.quote,
      loading
    }
  },
  methods: {
    async handleSave() {
      this.loading = true;
      QuoteService.save(this.quote);
      this.loading = false;
    }
  }
}
</script>
